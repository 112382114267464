<template>
    <div>
        <b-modal
            id="add-user-modal"
            centered
            style="max-width: 90%"
            size="lg"
            :dialog-class="{'wide-modal': step === 1}"
            :visible="modalShow"
            @hide="hideSelector"
            header-class="add-user-header"
            :hide-footer="step === 1"
            class="p-4 pb-0"
        >
            <template #modal-title>
                <div class="w-100">
                    <h3 class="font-weight-bold">
                        {{ $t('addNewUser') }}
                    </h3>
                </div>
            </template>
            <template #modal-header-close>
                <button class="bg-transparent border-0 text-white">
                    <b-icon-x />
                </button>
            </template>

            <div class="px-5 py-2 pt-4">
                <UserForm v-show="step === 0" @submit="handleFirstStep" ref="userForm" />
                <div v-show="step === 1">
                    <span>
                        {{ $t('associationDescription') }}
                    </span>
                    <EditAssociatedAccounts
                        class="pt-2"
                        @submit="handleCreateUser"
                        :isPlain="false"
                    />
                </div>
            </div>

            <template #modal-footer>
                <div class="d-flex px-5 pb-2 justify-content-between w-100">
                    <template v-if="step === 0">
                        <b-btn
                            id="reset-dialog"
                            variant="outline-primary"
                            @click="$refs.userForm.resetForm()"
                        >
                            {{ $t('reset') }}
                        </b-btn>
                        <b-btn
                            id="submit-dialog"
                            variant="primary"
                            @click="$refs.userForm.submitForm()"
                        >
                            {{ $t('continue') }}
                        </b-btn>
                    </template>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import UserForm from '@/views/admin/components/usermanagement/UserForm.vue';
import {mapState} from 'vuex';
import EditAssociatedAccounts from '@/views/admin/components/usermanagement/EditAssociatedAccounts.vue';

export default {
    components: {EditAssociatedAccounts, UserForm},
    props: {
        modalShow: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['added'],
    data: () => ({
        step: 0,
        formData: {},
    }),
    computed: {
        ...mapState({
            roles: (state) => state.roles.list,
        }),
    },
    methods: {
        hideSelector() {
            this.$emit('update:modalShow', false);
        },
        handleFirstStep(userData) {
            this.formData = {
                ...userData,
                role: this.roles.find((role) => role.roleId === userData.role),
                cultureCode: userData.language,
                isEnabled: true,
                businessUnits: userData.businessUnits.map((value) => ({businessUnitId: value})),
            };
            this.step += 1;
        },
        handleCreateUser(data) {
            this.blockingRequest('userList/createUser', {
                ...this.formData,
                ...data,
            }).then(() => {
                this.$emit('added');
                this.$bvModal.hide('add-user-modal');
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/css/variables';

.add-user-header {
    padding-left: 2rem;
    background-color: $primary-dark;
    font-size: 2rem;
    color: $white;
}

.wide-modal {
    max-width: 90%;
}
</style>
