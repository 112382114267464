<template>
    <div class="admin-table-container px-0 mx-0">
        <b-table
            responsive
            fixed
            no-local-sorting
            class="table-list"
            :items="users"
            :fields="fields"
            :sort-by="queryParams.sortBy"
            :sort-desc="queryParams.sortOrder === 'desc'"
            v-on="$listeners"
        >
            <template #table-colgroup="scope">
                <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{width: colWidths[field.key]}"
                />
            </template>

            <template #head(updated)="data">
                <div class="light-border-right white-space-pre">
                    <div class="d-flex column-explanation">
                        <div>
                            <span>{{ data.label }}</span>
                            <div class="mt-1 column-explanation-text">{{ t('dateHint') }}</div>
                        </div>
                    </div>
                </div>
            </template>

            <template #cell(name)="{item}">
                <div class="custom-data-label d-flex align-items-start text-truncate">
                    <b-avatar :size="20" class="mr-2"></b-avatar>
                    <div>
                        <p class="text-caption heavy text-black mb-1">
                            {{ namesFormatter(item.lastName, item.firstName) }}
                        </p>
                        <p class="text-caption light text-gray-dark mb-0">ID {{ item.userId }}</p>
                    </div>
                </div>
            </template>

            <template #cell(email)="data">
                <div class="overflowWrapAnywhere custom-data-label d-flex align-items-start">
                    {{ data.item.email }}
                </div>
            </template>

            <template #cell(enabled)="{item}">
                <b-tooltip :target="`enabled-${item.userId}`" :disabled="!isCurrentUser(item)">{{
                    t('adminCantDisableOwnAccount')
                }}</b-tooltip>
                <b-tooltip :target="`enabled-${item.userId}`" :disabled="!hasNoRole(item)">{{
                    t('cantEnableNoRole')
                }}</b-tooltip>
                <b-row :id="`enabled-${item.userId}`" align-h="center">
                    <b-form-checkbox
                        v-if="canEdit"
                        v-model="item.enabled"
                        :disabled="isCurrentUser(item) || hasNoRole(item)"
                        switch
                        @change="updateUser(item)"
                    >
                    </b-form-checkbox>
                    <template v-else>
                        {{ item.enabled ? t('yes') : t('no') }}
                    </template>
                </b-row>
            </template>

            <template #cell(action)="{item}">
                <div class="d-flex justify-content-center">
                    <custom-router-link
                        v-if="canEdit"
                        :to="{name: 'EditUser', params: {userId: Number(item.userId)}}"
                        class="text-primary"
                    >
                        <b-button variant="primary" type="button">
                            {{ t('Edit') }}
                            <b-icon class="ml-2" icon="pencil-square"></b-icon>
                        </b-button>
                    </custom-router-link>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex';
import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import date from '@/filters/date';
import {DecimalSeparators as decimalSeparators} from '@/constants/user';
import {ZoneWithBothCodes} from '@/constants/zone';
import {namesFormatter} from '@/utilities/formatters';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';

const DEFAULT_ZONE_USER_VALUES = {
    [ZoneWithBothCodes.US]: {
        language: 'en',
        decimalSeparator: '.',
    },
};

export default {
    name: 'UserTable',
    props: {
        queryParams: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            decimalSeparators,
            zones: Object.values(ZoneWithBothCodes),
            usersClone: [],
            colWidths: {
                updated: '104px',
                name: '150px',
                email: '200px',
                enabled: '85px',
                role: '130px',
                language: '150px',
                decimalSeparator: '100px',
                zones: '110px',
                action: '80px',
            },
        };
    },
    watch: {
        users() {
            this.usersClone = cloneDeep(this.users);
        },
    },
    computed: {
        ...mapState({
            users: (state) =>
                state.userList.list.map((user) => ({
                    ...user,
                    roleId: get(user, 'role.roleId', null),
                })),
        }),
        ...mapGetters('user', ['currentUser']),
        ...mapGetters('zone', ['currentZone']),
        ...mapGetters({
            permissions: 'permissions/permissions',
            languages: 'user/myLanguages',
            allRoles: 'roles/list',
        }),
        roles() {
            return this.allRoles.map((role) => set(role, 'disabled', !role.canAssign));
        },
        fields: function () {
            const fields = [
                {
                    key: 'updated',
                    label: this.t('activity'),
                    sortable: true,
                    tdClass: 'light-border white-space-pre',
                    formatter: (value) => date(value),
                },
                {key: 'name', label: this.t('userName'), sortable: true, stickyColumn: true},
                {key: 'email', label: this.t('emailAddress'), sortable: true},
                {key: 'enabled', label: this.t('enabled'), sortable: true, thClass: 'text-center'},
                ...(() => {
                    if (this.canEdit) {
                        return [
                            {
                                key: 'action',
                                label: '',
                            },
                        ];
                    }
                    return [];
                })(),
            ];
            return fields;
        },
        canEdit() {
            return this.checkPermissions({
                [PERMISSIONS.USERS]: PERMISSIONS_VALUES.WRITE_TYPES,
            });
        },
    },
    methods: {
        get,
        namesFormatter,
        ...mapActions('user', ['fetchMyForcedLogoutStatus']),
        ...mapActions({
            postUserUpdate: 'userList/updateUser',
        }),
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
        isCurrentUser({userId}) {
            return this.currentUser.userId == userId;
        },
        hasNoRole(user) {
            return !user.role?.roleId;
        },
        isUSUser(user) {
            return user.zones === ZoneWithBothCodes.US;
        },
        isRowDefault(user) {
            const userClone = this.usersClone.find((c) => c.userId == user.userId);
            return JSON.stringify(userClone) === JSON.stringify(user);
        },
        onZonesUserChange(user, zone) {
            if (zone === ZoneWithBothCodes.US) {
                const {language, decimalSeparator} = DEFAULT_ZONE_USER_VALUES[ZoneWithBothCodes.US];
                user.language = language;
                user.decimalSeparator = decimalSeparator;
            }
        },
        async updateUser(user) {
            try {
                await this.postUserUpdate({
                    userId: user.userId,
                    isEnabled: user.enabled,
                });
                // TODO: remove this line
                // this.$emit('refresh-records');
                if (this.isCurrentUser(user)) {
                    await this.fetchMyForcedLogoutStatus();
                    if (this.currentUser?.forcedLogoutStatus) {
                        this.$router.push({name: 'LoggingOut'});
                    }
                }
            } catch (err) {
                user.enabled = !user.enabled;
                this.$bvToast.toast(this.$t('unableToUpdateUser'), {
                    title: this.$t('error'),
                    variant: 'danger',
                });
            }
        },
    },
    async mounted() {
        if (this.canEdit) {
            await this.blockingRequest('roles/fetchList');
        }
    },
};
</script>
<style scoped>
.custom-data-label {
    color: black;
    font-weight: 600;
}

.custom-header-label {
    color: rgb(133, 132, 132);
}

.boldRadio {
    font-weight: 600;
    color: black;
}

.overflowWrapAnywhere {
    overflow-wrap: anywhere;
}
.w-85px {
    width: 85px;
}
</style>
